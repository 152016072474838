//------------------------------------------
//  Simple Offer
//------------------------------------------

var SimpleOffer = {
    simpleOffer         : {},
    buttons             : {},
    closeButtons        : {},
    simpleOfferId       : 'simple-offer',
    simpleOfferModifier : 'is-active',
    buttonsClass        : 'c-button',
    closeButtonsClass   : 'js-closeSimpleOffer',
    delay               : 5000,
    cookie              : {
        name    : 'simple_offer',
        value   : true,
        exdays  : 30,
        path    : '/'
    },


    // check if the simple offer cookie has been set
    checkCookie: function() {
        return getCookie('simple_offer');
    },

    // set the simple offer cookie
    setCookie: function() {
        var cookie = this.cookie;
        setCookie(cookie.name, cookie.value, cookie.exdays, cookie.path);
    },

    // show the simple offer
    show: function() {
        this.simpleOffer.classList.add(this.simpleOfferModifier);
    },

    // hide the simple offer
    hide: function() {
        this.simpleOffer.classList.remove(this.simpleOfferModifier);
        var simpleOfferMinimal = document.getElementById('simple-offer-minimized');
        simpleOfferMinimal.classList.add('is-active');
    },

    // get the simple offer element
    getSimpleOffer: function() {
        this.simpleOffer    = document.getElementById(this.simpleOfferId);
    },

    // sets all the simple offer elements
    getButtons: function() {
        this.buttons        = this.simpleOffer.getElementsByClassName(this.buttonsClass);
        this.closeButtons   = document.getElementsByClassName(this.closeButtonsClass);
    },

    // check if the page has a simple offer
    enabled: function() {
        var enabled = this.simpleOffer ? true : false;

        return enabled;
    },

    init: function() {
        // get the simple offer elements
        this.getSimpleOffer();

        //check to see if the page has a simple offer
        if (this.enabled()) {

            // get the buttons
            this.getButtons();

            // check if cookie 'simple_offer' has been set
            if( !SimpleOffer.checkCookie() ) {
                // if not, show simple offer after delay
                setTimeout(function() {
                    SimpleOffer.show();
                }, this.delay);
            }

            // add onclick functions to simple offer buttons
            for (var i = 0; i < this.buttons.length; i++) {
                this.buttons[i].onclick = function() {
                    SimpleOffer.setCookie();
                }
            }

            // add onclick functions to simple offer close buttons
            for (var i = 0; i < this.closeButtons.length; i++) {
                this.closeButtons[i].onclick = function() {
                    var offer = SimpleOffer.buttons[0].getAttribute('href');
                    window.simpleOfferClosed(offer);

                    SimpleOffer.setCookie();
                    SimpleOffer.hide();
                }
            }
        } // endif

    } //end init

} //end SimpleOffer

SimpleOffer.init();



