jQuery.noConflict();
(function( $ ) {
  $(function() {
      $( document ).ready(function() {



          //simple tab switcher for .c-tabs
          $('.js-activateTab').on('click', function(e) {
              e.preventDefault();

              var $clicked = $(this),
                  activateSelector = $clicked.attr('href'),
                  $activate = $(activateSelector),
                  $tabs = $clicked.parents('.c-tabs');

              $tabs.find('.is-active').removeClass('is-active');

              $clicked.addClass('is-active');
              $activate.addClass('is-active');
          })



      });

  });
})(jQuery);
