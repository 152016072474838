jQuery.noConflict();
(function($) {
    $(function() {


        $(document).ready(function() {
            // js-mobile-aside
            var asideSelector = '.js-mobile-aside',
                $aside = $(asideSelector);

            var breakpointDownM = Modernizr.mq('(max-width: 54.9375em)');


            //custom scroll to for pleurals backpage mobile nav
            $('.js-scrollTo').on('click', function(e) {
                e.preventDefault();

                var $clicked = $(this),
                target_selector = $clicked.attr('href'),
                $target = $(target_selector);

                if (typeof bodyScrollLock.clearAllBodyScrollLocks === "function") {
                    bodyScrollLock.clearAllBodyScrollLocks();
                }
                deactivateElement($aside);

                var offset = $target.offset().top - 16,
                    $siteHeader = $('.c-site-header');

                //check to see if you are at the 'm' or smaller breakpoint
                if (breakpointDownM) {
                    var headerHeight = $siteHeader.outerHeight();
                    offset = offset - headerHeight;
                }

                //set scroll to 0 if less than 0
                if (offset < 0) {
                    offset = 0;
                }

                //adjust the animation duration depending on the distance traveled
                var currentScroll = document.documentElement.scrollTop,
                    duration = (Math.ceil(Math.abs(currentScroll - offset) / 1000) * 30) + 500;

                $("html, body").animate({
                    scrollTop: offset
                }, duration);
            });

            function deactivateElement(el) {
                el.removeClass('is-active');
            }

            function activateElement(el) {
                el.addClass('is-active');
            }

            function openAside() {
                $('.c-aside__section').hide();
                activateElement($aside);

                bodyScrollLock.disableBodyScroll(document.querySelector(asideSelector));
            }

            function closeAside() {
                deactivateElement($aside);

                bodyScrollLock.enableBodyScroll(document.querySelector(asideSelector));

                deactivateActiveAsideSection(null, 0);

                setTimeout(function() {
                    $('.c-aside__section').show();
                }, 200);
            }

            function deactivateActiveAsideSection(callback, duration) {
                var callback = (typeof callback !== 'undefined') ? callback : null,
                    duration = (typeof duration !== 'undefined') ? duration : 200,
                    $activeSection = $(document).find('.c-backpage-mobile-nav__button.is-active'),
                    activeSectionSelctor = $activeSection.data('section');

                if ($activeSection.length > 0) {
                    deactivateAsideSection(activeSectionSelctor, callback, duration);
                }
            }

            function deactivateAsideSection(sectionSelector, callback, duration) {
                var callback = (typeof callback !== 'undefined') ? callback : null,
                    duration = (typeof duration !== 'undefined') ? duration : 200,
                    $section = $(sectionSelector),
                    $sectionButton = $('.c-backpage-mobile-nav__button[data-section="'+sectionSelector+'"]');

                $section.fadeOut(duration, function() {
                    deactivateElement($section);
                    deactivateElement($sectionButton);

                    if (typeof callback === "function") {
                        callback();
                    }
                })
            }

            function activateAsideSection(sectionSelector, duration) {


                var duration = (typeof duration !== 'undefined') ? duration : 200,
                    $section = $(sectionSelector),
                    $sectionButton = $('.c-backpage-mobile-nav__button[data-section="'+sectionSelector+'"]');

                $section.fadeIn(duration, function() {
                    activateElement($section);
                    activateElement($sectionButton);
                })
            }

            $(window).resize(function() {
                closeAside();
            })


            $(document).on('click', '.js-scrollToTop', function(e) {
                var currentScroll = document.documentElement.scrollTop,
                    duration = (Math.ceil(currentScroll / 1000) * 20) + 250;

                $("html, body").animate({ scrollTop: 0 }, duration);
                return false;
            })


            $(document).on('click', '.js-closeAside', function(e) {
                e.preventDefault();
                closeAside();
            })


            $(document).on('click', '.js-activateAsideSection', function(e) {
                e.preventDefault();

                if (!$aside.hasClass('is-active')) {
                    openAside();
                }

                var clickedSectionSelector = $(this).data('section'),
                    $activeSection = $(document).find('.c-backpage-mobile-nav__button.is-active'),
                    activeSectionSelctor = $activeSection.data('section');

                if( clickedSectionSelector != activeSectionSelctor && $activeSection.length > 0 ) {
                    deactivateAsideSection(activeSectionSelctor, function() {
                        activateAsideSection(clickedSectionSelector);
                    })
                } else {
                    activateAsideSection(clickedSectionSelector, 0);
                }
            })



        });

    });
})(jQuery);
